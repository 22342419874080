require('./bootstrap');
require('magnific-popup')
require('./mapStyle')
const moment = require("moment")
import flatpickr from "flatpickr";
import { Slovak } from "flatpickr/dist/l10n/sk.js";
import Cookies from 'js-cookie';

let eventFired = false;
let dateEl = document.querySelectorAll('.flatpickr-date')
let carousel = document.getElementById('home-slider')

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    type: 'POST',
    cache: false
})

const initGallery = () => {
    $('#images').magnificPopup({
        delegate: 'a[class="active"]', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true
        },
        removalDelay: 500,
        callbacks: {
            beforeOpen: function() {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        closeOnContentClick: true,
        midClick: true
    });
}

const moveCarousel = () => {
    let carouselMobile = document.getElementById('mobile-carousel')
    carouselMobile.append(carousel);
}

$('.carousel').on('slid.bs.carousel', function () {
    $('.carousel-text h1').html($('.active > .carousel-caption').data('text'));
});

if ($(window).width() < 768) {
    if($('#pages-home').length) {
        moveCarousel()
    }
} else {
    eventFired = true;
}

$(window).on('resize', function() {
    if ($('#pages-home').length && !eventFired) {
        if ($(window).width() < 768) {
            moveCarousel()
        }
    }
});

dateEl.forEach(function (e, i) {
    flatpickr(e, {
        locale: Slovak,
        dateFormat: "d.m.Y",
        disableMobile: "true",
        minDate: moment().format('DD.MM.YYYY'),
        wrap: true
    });
});


$('.hamburger').click(function () {
    $('body').toggleClass('menu-open')
    $(this).toggleClass('is-active')
})

let map;

function initMap() {
    let centerLat = 48.961;
    let centerLng = 19.59;
    let zoom = 15
    let position = google.maps.ControlPosition.RIGHT_CENTER
    if($(window).width() < 992) {
        centerLat = 48.967
        centerLng = 19.58
        zoom = 14
        position = google.maps.ControlPosition.RIGHT_BOTTOM
    }

    let mapConfig = {
        center: { lat: centerLat, lng: centerLng },
        zoom: zoom,
        gestureHandling: 'greedy',
        scrollwheel: false,
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: position
        },
        fullscreenControl: true,
        styles: getMapStyle.styles
    };

    map = new google.maps.Map(document.getElementById("map"), mapConfig);
    const image =
        "/images/map_point.png";
    const beachMarker = new google.maps.Marker({
        position: { lat: 48.96615614926, lng: 19.576564790844 },
        map,
        icon: image
    });
}


$(function () {
    if(!$('#pages-home').length) {
        setTimeout(function(){
            initMap()
        }, 100);
    }
    if($('#pages-gallery').length) {
        initGallery()
        $('.gallery-title').on('click', function () {
            $('.gallery-title').each(function () {
                $(this).removeClass('active')
            })
            $(this).addClass('active')
            let galleryId = $(this).data('gallery-id')
            $('.images a').each(function () {
                if(galleryId === 0) {
                    $(this).removeClass('hide').addClass('active');
                } else {
                    if($(this).data('gallery-id') !== galleryId) {
                        $(this).addClass('hide').removeClass('active');
                    } else {
                        $(this).removeClass('hide').addClass('active');
                    }
                }
            })
        })
    }
})

$('.arrows>div').on('click', function () {
    if(!$(this).hasClass('disabled')) {
        let page = $(this).data('page');
        let count = 1;
        let type = 'next';
        if($('#pages-novinky').length) {
            count = 5;
        }
        if($(this).hasClass('prev')) {
            type = 'prev';
        }
        $.ajax({
            url: '/novinky/?page=' + page,
            method: 'GET',
            data: {
                count: count,
                type: type
            },
            dataType: 'html',
            error: function (jqXHR, textStatus, errorThrown) {
            },
            success: function(data) {
                let prevDiv = $('.arrows .prev');
                let prevDivImg = $('.arrows .prev img');
                let nextDiv = $('.arrows .next');
                let nextDivImg = $('.arrows .next img');

                if(page === 1) {
                    prevDiv.addClass('disabled')
                    prevDivImg.attr('src', '/images/sipka-vlavo-disabled.svg')
                } else if(prevDiv.hasClass('disabled')) {
                    prevDiv.removeClass('disabled')
                    prevDivImg.attr('src', '/images/sipka-vlavo.svg')
                }
                if(parseInt(nextDiv.data('last-page')) === page) {
                    nextDiv.addClass('disabled')
                    nextDivImg.attr('src', '/images/sipka-vpravo-disabled.svg')
                } else if(nextDiv.hasClass('disabled')) {
                    nextDiv.removeClass('disabled')
                    nextDivImg.attr('src', '/images/sipka-vpravo.svg')
                }

                $("#new_container").empty().html(data);
                prevDiv.data('page', page-1)
                nextDiv.data('page', page+1)
            }
        });
        return true;
    }
    return false;
})

$(function () {
    const cookieBar = $('#cookieBar');
    const removeCookieBarButton = $('#removeCookieBarButton');

    removeCookieBarButton.on('click tap', function () {
        Cookies.set('removeCookieBar', 1, {
            expires: 7
        });

        cookieBar.remove();
    });

    if (Cookies.get('removeCookieBar')) {
        cookieBar.remove();
    } else {
        cookieBar.removeClass('d-none');
    }
})
